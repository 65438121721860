.unsupported-browser-container {
    display: none;
    width: 100%;
    height: 100%;
    font-family: Poppins, Arial, Helvetica, sans-serif;
    top: 0;
    left: 0;
    box-sizing: content-box !important;
    z-index: 99999;
    text-align: center;
    position: fixed;
}

.unsupported-browser-container .unsupported-browser-modal {
    width: 600px;
    min-height: 360px;
    background: #fff;
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -300px;
    margin-top: -200px;
    color: #434a54;
    z-index: 99999;
}

.unsupported-browser-container .unsupported-browser-header {
    padding-top: 10px;
}
.unsupported-browser-container .unsupported-browser-header h1 {
    color: #00a0dc;
}

.unsupported-browser-container .unsupported-browser-browsers {
    list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow: auto;
    text-align: center;
    direction: ltr;
}

.unsupported-browser-container .unsupported-browser-browser-item {
    width: 105px;
    height: 120px;
    position: relative;
    display: inline-block;
}

.unsupported-browser-container .unsupported-browser-browser-item a {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    color: #434a54;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
}

.unsupported-browser-container .unsupported-browser-browser-item a:hover .unsupported-browser-browser-name {
    background-color: #00a0dc;
    color: #fff;
}

.unsupported-browser-container .unsupported-browser-browser-icon {
    display: block;
    width: 100%;
    height: 90px;
    margin: 0 auto;
    background-size: cover;
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
}

.unsupported-browser-container .unsupported-browser-browser-icon.unsupported-browser-browser-icon-chrome {
    background-image: url(../../../assets/img/unsupported-browser-message/chrome.png)
}

.unsupported-browser-container .unsupported-browser-browser-icon.unsupported-browser-browser-icon-firefox {
    background-image: url(../../../assets/img/unsupported-browser-message/firefox.png)
}

.unsupported-browser-container .unsupported-browser-browser-icon.unsupported-browser-browser-icon-safari {
    background-image: url(../../../assets/img/unsupported-browser-message/safari.png)
}

.unsupported-browser-container .unsupported-browser-browser-icon.unsupported-browser-browser-icon-opera {
    background-image: url(../../../assets/img/unsupported-browser-message/opera.png)
}

.unsupported-browser-container .unsupported-browser-browser-icon.unsupported-browser-browser-icon-edge {
    background-image: url(../../../assets/img/unsupported-browser-message/me.png)
}

.unsupported-browser-container .unsupported-browser-layout {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #ffffff;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
    filter: alpha(opacity=80);
    z-index: 99998;
}

.unsupported-browser-container .unsupported-browser-footer .unsupported-browser-footer-continue {
    margin: 20px auto;
    display: inline-block;
    padding: 5px 15px;
    color: #fff;
    background-color: #00a0dc;
    font-size: 20px;
    line-height: 30px;
    cursor: pointer;
    text-decoration: none;
    border-radius: 4px;
}
.unsupported-browser-container .unsupported-browser-footer .unsupported-browser-footer-continue:hover {
    background-color: #00a0dc;
}

@media (max-width: 758px) {
    .unsupported-browser-container {
        overflow-y: auto;
    }

    .unsupported-browser-container  .unsupported-browser-modal {
        width: 300px;
        top: 0;
        padding: 10px;
        margin-left: -150px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}
